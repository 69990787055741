import imgT1 from "../assets/image/jpg/dev/masonry/erus.webp";
import imgT2 from "../assets/image/jpg/dev/masonry/kite.webp";
import imgT3 from "../assets/image/jpg/dev/masonry/certificate.webp";
import imgT4 from "../assets/image/jpg/dev/masonry/wingman.webp";
import imgT5 from "../assets/image/jpg/dev/masonry/alumni.webp";
import imgT6 from "../assets/image/jpg/dev/masonry/cov.webp";
import imgT7 from "../assets/image/jpg/dev/masonry/caffenio.webp";
import imgT8 from "../assets/image/jpg/dev/masonry/vr.webp";
import imgT9 from "../assets/image/jpg/dev/masonry/novutek.webp";

export const devWorks1 = [
  {
    brand: `Erus`,
    slug: `erus-web`,
    title: `Erus`,
    categories: [`web`],
    thumbnail: imgT1,
  link : "/erus"
},
  {
    brand: `Kite`,
    slug: `kite-web`,
    title: `Kite`,
    categories: [`web`],
    thumbnail: imgT2,
  link : "/kite"
},
  {
    brand: `Erus Certificaciones`,
    slug: `erus-certificaciones-web`,
    title: `Erus Certificaciones`,
    categories: [`web`],
    thumbnail: imgT3,
  link : "/erus-certificaciones"
},
  {
    brand: `Wingman`,
    slug: `wingman-web`,
    title: `Wingman`,
    categories: [`web`],
    thumbnail: imgT4,
  link : "/wingman"
},
  {
    brand: `Alumni`,
    slug: `alumni-web`,
    title: `Alumni`,
    categories: [`web`],
    thumbnail: imgT5,
  link : "/alumni"
},
  {
    brand: `Eventos COV`,
    slug: `eventos-web`,
    title: `Eventos COV`,
    categories: [`web`],
    thumbnail: imgT6,
  link : "/eventos-cov"
},
{
  brand: `Caffenio`,
  slug: `caffenio-web`,
  title: `Caffenio`,
  categories: [`web`],
  thumbnail: imgT7,
link : "/caffenio"
},
{
  brand: `Nasa SpaceApps`,
  slug: `nasa-vr`,
  title: `Nasa SpaceApps`,
  categories: [`vr`],
  thumbnail: imgT8,
link : "/nasa-spaceapps"
},
{
  brand: `Novutek UI`,
  slug: `novutek-ui`,
  title: `Novutek UI`,
  categories: [`ui`],
  thumbnail: imgT9,
link : "/novutek-ui"
},
];
